import { useDocumentVisibility } from 'ahooks';
import { User } from '../types';
import {
    useActivitySensor,
    useNetworkState,
    usePoll,
} from '@archipro-website/react-hooks';

const DEFAULT_NOTIFICATION_INTERVAL = 12000;

export function useHandleNotifications(user: User, onPoll: () => void): void {
    const pageVisibility = useDocumentVisibility();

    const active = useActivitySensor({
        debounceMs: DEFAULT_NOTIFICATION_INTERVAL,
    });
    const { isOnline } = useNetworkState();

    usePoll(
        ({ kill }) => {
            if (!user || user.__typename === 'Guest' || !active || !isOnline) {
                kill();
                return;
            }
            if (pageVisibility === 'visible') {
                onPoll();
            }
        },
        [user, active, isOnline],
        {
            interval: DEFAULT_NOTIFICATION_INTERVAL,
        }
    );
}
