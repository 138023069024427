import {
    useStyles,
    Subscription,
    Image,
    useAppDisplayModeContext,
    Subheader,
    Caption,
    pxToRem,
} from '@archipro-design/aria';
import * as S from './FooterSubscription.style';

import { CustomArrowRight } from '@archipro-design/icons';
import {
    createElement,
    useEffect,
    type FunctionComponent,
    type RefAttributes,
} from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const subscriptionSchema = z.object({
    register_newsletter_user_email: z
        .string()
        .min(5, { message: 'Email address is required' })
        .email('This is not a valid email address'),
});

type SubscriptionAttributes = z.infer<typeof subscriptionSchema>;

interface FooterSubscriptionProps {
    formElement?: 'form' | FunctionComponent<RefAttributes<HTMLFormElement>>;
    onSubmit: (email: string) => void;
    error?: string;
    success?: boolean;
    loading?: boolean;
}

const FOOTER_BACKGROUND_DESKTOP =
    'https://assets.archipro.co.nz/assets/website/homepage/footer-subscription-desktop-2.png';

const FOOTER_BACKGROUND_MOBILE =
    'https://assets.archipro.co.nz/assets/website/homepage/footer-subscription-mobile-2.png';

const FooterSubscription = ({
    formElement,
    onSubmit: onSubmitProp,
    error,
    loading,
    success,
}: FooterSubscriptionProps) => {
    const { css } = useStyles();
    const { desktop } = useAppDisplayModeContext();

    const {
        handleSubmit,
        register,
        reset,
        clearErrors,
        setError,
        formState: { errors },
        getValues,
    } = useForm<SubscriptionAttributes>({
        resolver: zodResolver(subscriptionSchema),
        mode: 'onChange',
    });
    const { onChange, name, ref } = register('register_newsletter_user_email');
    const onSubmit: SubmitHandler<{
        register_newsletter_user_email: string;
    }> = (data) => {
        const { register_newsletter_user_email: email } = data;
        if (email && !errors?.register_newsletter_user_email?.message) {
            onSubmitProp(email.toLowerCase());
        }
    };

    useEffect(() => {
        if (!success || loading) return;
        reset({ register_newsletter_user_email: '' });
    }, [success, reset, loading]);

    useEffect(() => {
        if (!error || loading) return;
        setError('register_newsletter_user_email', { message: error });
    }, [error, setError, loading]);

    return (
        <div className={css(S.FooterSubscriptionContainer)}>
            <div className={css(S.FooterSubscriptionContentContainer)}>
                <div className={css(S.FormContainer)}>
                    <Subheader className={css(S.FooterTitle)}>
                        Subscribe to our Digital Magazines
                    </Subheader>
                    <Caption className={css(S.FooterContent)}>
                        Stay informed about the newest projects, products, and
                        technologies shaping the future of the architecture and
                        design environment.
                    </Caption>
                    {createElement(
                        formElement as 'form',
                        {
                            style: {
                                width: '100%',
                            },
                            onSubmit: handleSubmit(onSubmit),
                        },
                        Subscription.create({
                            submit: {
                                type: 'submit',
                                children: desktop ? 'SUBSCRIBE' : ' ',
                                icon: desktop ? undefined : (
                                    <CustomArrowRight
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                ),
                                size: desktop ? 16 : 24,
                                variant: desktop ? 'contained' : 'text',
                                color: 'dark',
                                variables: {
                                    iconPadding: 0,
                                },
                                loading,
                            },
                            input: {
                                id: 'footer-subscription-email',
                                name,
                                value:
                                    getValues(
                                        'register_newsletter_user_email'
                                    ) ?? '',
                                inputRef: ref,
                                successIndicator: {},
                                onBlur: (e) => {
                                    e.target.value =
                                        e.target.value.toLowerCase();
                                },
                                onChange: (e) => {
                                    clearErrors(
                                        'register_newsletter_user_email'
                                    );
                                    return void onChange(e);
                                },
                                type: 'email',
                            },
                            variables: {
                                textInputWidth: pxToRem(352),
                            },
                            error: errors?.register_newsletter_user_email
                                ?.message,
                        })
                    )}
                </div>
            </div>
            <div className={css(S.ImageContainer)}>
                <Image
                    src={
                        desktop
                            ? FOOTER_BACKGROUND_DESKTOP
                            : FOOTER_BACKGROUND_MOBILE
                    }
                    width={
                        desktop
                            ? S.FOOTER_BACKGROUND_DESKTOP_WIDTH
                            : S.FOOTER_BACKGROUND_MOBILE_WIDTH
                    }
                    height={
                        desktop
                            ? S.FOOTER_BACKGROUND_DESKTOP_HEIGHT
                            : S.FOOTER_BACKGROUND_MOBILE_HEIGHT
                    }
                    layout={'fill'}
                    objectFit={'cover'}
                    alt={'Footer Images'}
                    variables={{
                        placeholderBackgroundColorDark: 'transparent',
                        placeholderBackgroundColorLight: 'transparent',
                    }}
                />
            </div>
        </div>
    );
};

export default FooterSubscription;
