import type { DependencyList } from 'react';
import { useEffect } from 'react';

interface Config {
    interval?: number;
}

function usePoll(
    func: ({ kill }: { kill(): void }) => void,
    deps: DependencyList | undefined = undefined,
    config: Config = {}
): void {
    if (typeof func !== 'function')
        throw new TypeError("Can't poll without a callback function");

    const { interval = 5000 } = config;

    return useEffect(() => {
        let killed = false;
        const kill = () => {
            killed = true;
        };

        const poll = async () => {
            if (killed) return;

            func({ kill });
            setTimeout(poll, interval);
        };

        poll();

        return () => {
            killed = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

export default usePoll;
